import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { useLocation } from '@reach/router';
import { ClickAwayListener, MenuList, MenuItem as MuiMenuItem, Hidden } from '@material-ui/core';
import { Group, KeyboardArrowDown, PermIdentity, AmpStories, Person } from '@material-ui/icons';
import { breakpoints } from '@avangard/ui/utils';
import { white } from '@avangard/ui/colors';

import { createRouteUrl, changePlatform, useNavigate } from '@lib/routing';
import { routes } from '@config/routes';
import { PopperPopup } from '@modules/layout/moleculas';
import { logout } from '@modules/auth/helpers';

type HeaderUserInfoProps = {
    fullName: string;
    role: string;
};

const StyledPopperPopup = styled(PopperPopup)`
    margin-inline-start: -14px;
    z-index: 99;
`;

const AwayListenerRoot = styled.div``;

const Root = styled.div`
    display: flex;
    align-items: center;
    min-width: 125px;
    height: 64px;
    padding: 0 24px;
    text-align: right;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: ${white[90]};
    }

    ${breakpoints.down('xs')} {
        min-width: auto;
        height: 44px;
        padding: 0 14px;
    }
`;

const User = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const FullName = styled.div`
    font-size: 1.4rem;
`;

const Role = styled.div`
    font-size: 1rem;
    font-style: italic;
`;

const Icon = styled.div`
    display: flex;
    width: 18px;
    height: 18px;
    margin-inline-start: 8px;

    ${breakpoints.down('xs')} {
        width: 24px;
        height: 24px;
        margin-inline-start: 0;
    }
`;

const MenuItem = styled(MuiMenuItem)`
    > span:first-child {
        display: flex;
        width: 18px;
        height: 18px;
    }

    > p {
        max-width: calc(100% - 32px);
        margin-inline-start: 8px;
    }
`;

const personIcon = <Person style={{ fontSize: 18 }} />;
const arrowDownIcon = <KeyboardArrowDown style={{ fontSize: 18 }} />;
const permIdentityIcon = <PermIdentity style={{ fontSize: 24 }} />;
const groupIcon = <Group style={{ fontSize: 18 }} />;
const ampStoriesIcon = <AmpStories style={{ fontSize: 18 }} />;

const HeaderUserInfo = (props: HeaderUserInfoProps): React.ReactElement => {
    const { fullName, role } = props;

    const { t } = useTranslation('common');

    const apolloClient = useApolloClient();

    const location = useLocation();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopperClose = (): void => setAnchorEl(null);

    const handleClickPopperToggle = (event: React.SyntheticEvent<HTMLDivElement>): void =>
        setAnchorEl(anchorEl ? null : event.currentTarget);

    const handleClickGoProfile = (): void => {
        navigate(createRouteUrl(routes.profile));
    };

    const handleClickChangePlatform = (): void => changePlatform();
    const handleClickLogout = (): Promise<void> => logout(apolloClient);

    React.useEffect(() => {
        if (anchorEl) {
            handlePopperClose();
        }
    }, [location.pathname]);

    return (
        <ClickAwayListener onClickAway={handlePopperClose}>
            <AwayListenerRoot>
                <Root onClick={handleClickPopperToggle}>
                    <Hidden xsDown>
                        <User>
                            <FullName>{fullName}</FullName>
                            <Role>{role}</Role>
                        </User>

                        <Icon>{arrowDownIcon}</Icon>
                    </Hidden>

                    <Hidden smUp>
                        <Icon>{permIdentityIcon}</Icon>
                    </Hidden>
                </Root>

                <StyledPopperPopup placement='bottom-end' open={!!anchorEl} anchorEl={anchorEl}>
                    <MenuList>
                        <MenuItem onClick={handleClickGoProfile}>
                            <span>{personIcon}</span>
                            <p>{t('user.profile')}</p>
                        </MenuItem>

                        <MenuItem onClick={handleClickChangePlatform}>
                            <span>{ampStoriesIcon}</span>
                            <p>{t('user.change_platform')}</p>
                        </MenuItem>

                        <MenuItem onClick={handleClickLogout}>
                            <span>{groupIcon}</span>
                            <p>{t('actions.exit')}</p>
                        </MenuItem>
                    </MenuList>
                </StyledPopperPopup>
            </AwayListenerRoot>
        </ClickAwayListener>
    );
};

export { HeaderUserInfo };
