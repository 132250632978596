import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const SUPPORTED_LANGUAGES = ['ru', 'en', 'ar'];
export const DEFAULT_LANGUAGE = 'ru';

export const initI18n = async () =>
    i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            load: 'languageOnly',
            debug: false,
            fallbackLng: DEFAULT_LANGUAGE,
            supportedLngs: SUPPORTED_LANGUAGES,
            detection: {
                order: ['path'],
                lookupFromPathIndex: 0,
                caches: [],
            },
            saveMissing: true,
            defaultNS: 'common',
            fallbackNS: 'common',
            ns: [
                'common',
                'errors',
                'notifiers',
                'access',
                'auth',
                'summary',
                'dashboard',
                'employees',
                'regional_center',
            ],
            missingKeyHandler: (_, __, key) => {
                // eslint-disable-next-line no-console
                console.warn('Translation was not found: ' + key);
            },
            parseMissingKeyHandler: () => {
                const warningIcon = '\u2757';

                return warningIcon + ' Failed to load translation';
            },
            backend: {
                loadPath: `/locales/{{lng}}/{{ns}}.json?version=${I18N_TIMESTAMP}`,
            },
            interpolation: {
                escapeValue: false,
            },
            react: {
                useSuspense: true,
            },
        });

export { i18n };
