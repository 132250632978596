import { gql } from '@apollo/client';

export const GetLocationsQuery = gql`
    query GetLocations($regionIds: [ID], $regionId: ID, $lapId: ID) {
        getLocations(regionIds: $regionIds, regionId: $regionId, lapId: $lapId) {
            id
            name
        }
    }
`;
