import { UserRole } from '@modules/types/graphql';

import type { Classifiers } from './typings';

const permissions: Record<UserRole, Partial<Classifiers>> = {
    [UserRole.superAdmin]: {
        dashboard: ['all'],
        regionalCenters: ['all'],
    },
    [UserRole.admin]: {},
    [UserRole.student]: {},
    [UserRole.school]: {},
    [UserRole.methodist]: {},
    [UserRole.registrar]: {},
    [UserRole.instructor]: {},
    [UserRole.mentor]: {},
    [UserRole.leader]: {},
    [UserRole.juniorLeader]: {},
    [UserRole.seniorInstructor]: {},
    [UserRole.psychologist]: {},
    [UserRole.organizingTeacher]: {},
};

const checkPermission = (role: UserRole | null, classifiersKey: string, classifier: string) => {
    if (!role) {
        return false;
    }

    const permissionsClassifiers: string[] = permissions[role][classifiersKey];

    if (permissionsClassifiers) {
        const hasPermission =
            permissionsClassifiers.includes('all') || permissionsClassifiers.includes(classifier);

        return hasPermission;
    }

    return false;
};

export { checkPermission };
