import format from 'date-fns/format';

import { BaseEntity, Entity as DecribeEntity, Relation, RelationList } from '@lib/entity';
import { i18n, getLocaleForDateFns } from '@lib/i18n';
import { LocationEntity } from '@modules/shared/entities';
import { LapAccessEntityList } from '@modules/lap/entities';
import { UserEntity } from '@modules/user/entities';
import { schoolTypeTranslates, studentsAddModeTranslates } from '@modules/schools/constants';
import { SchoolType, StudentsAddMode } from '@modules/types/graphql';

import type { LapAccessEntity } from '@modules/lap/entities';
import type { UserEntityType } from '@modules/user/entities';
import type { School } from '@modules/types/graphql';

export type Entity =
    | (Partial<Omit<School, 'attachedUser'>> & {
          attachedUser?: UserEntityType;
      })
    | null;

@DecribeEntity('SchoolEntity')
class SchoolEntity extends BaseEntity {
    id: string;
    type: SchoolType | null;
    shortName: string;
    fullName: string;
    number: string;
    profile: string;
    responsiblePerson: string;
    currentStudentsCount: number;
    maxStudentsCount: number;
    studentsAddMode: StudentsAddMode | null;
    phone: string;
    email: string;
    accessSentAt: string;
    isAccessSent: boolean;
    accessSentStatus: string;
    accessSentStatusText: string;
    canDeleteStudent: boolean;

    @Relation(() => LocationEntity)
    location: LocationEntity;

    @Relation(() => UserEntity)
    attachedUser: UserEntity;

    @RelationList(() => LapAccessEntityList)
    lapAccessList: (LapAccessEntity | null)[];

    constructor(school: Entity) {
        super(school);

        this.id = school?.id ?? '';
        this.type = school?.type ?? null;
        this.shortName = school?.shortName || '';
        this.fullName = school?.fullName || '';
        this.number = school?.number ? String(school.number) : '';
        this.profile = school?.profile || '';
        this.responsiblePerson = school?.responsiblePerson || '';
        this.currentStudentsCount = school?.currentStudentsCount ?? 0;
        this.maxStudentsCount = school?.maxStudentsCount ?? 0;
        this.studentsAddMode = school?.studentsAddMode ?? null;
        this.phone = school?.phone || '';
        this.email = school?.email || '';
        this.accessSentAt = school?.accessSentAt ?? '';
        this.isAccessSent = school?.isAccessSent ?? false;
        this.accessSentStatus = school?.accessSentStatus ?? '';
        this.accessSentStatusText = school?.accessSentStatusText ?? '';
        this.canDeleteStudent = school?.canDeleteStudent ?? true;
    }

    canCreateStudent() {
        if (this.studentsAddMode === StudentsAddMode.fullLimited) {
            return false;
        }

        if (this.studentsAddMode === StudentsAddMode.limited) {
            const canCreate = this.currentStudentsCount < this.maxStudentsCount;

            return canCreate;
        }

        return true;
    }

    getFormattedAccessSentDate() {
        if (this.accessSentAt === null) {
            return '-';
        }

        try {
            const validDate = new Date(this.accessSentAt);
            const date = format(validDate, 'dd.MM.yyyy');
            const time = format(validDate, 'HH:mm:ss');

            const formatted = i18n.t('common:blocks.time.date_to_minutes', { date, time });

            return formatted;
        } catch {
            return '-';
        }
    }

    getHumanSchoolType() {
        if (this.type === null) {
            return '-';
        }

        const humanableType = i18n.t(schoolTypeTranslates[this.type]);

        return humanableType;
    }

    getFormattedStudentsAddMode() {
        if (this.studentsAddMode === null) {
            return '-';
        }

        if (this.studentsAddMode === StudentsAddMode.limited) {
            return this.maxStudentsCount;
        }

        return i18n.t(studentsAddModeTranslates[this.studentsAddMode]);
    }

    getLapsNames(): string[] {
        const result = this.lapAccessList
            .map(lapAccess => lapAccess?.lap ?? null)
            .map(lap => {
                if (!lap || lap.from === '' || lap.to === '') {
                    return '';
                }

                const fromDate = lap.from ? new Date(lap.from) : null;
                const toDate = lap.to ? new Date(lap.to) : null;

                const locale = getLocaleForDateFns();

                const from = fromDate ? format(fromDate, 'dd.MM.yyyy', { locale }) : '//';
                const to = toDate ? format(toDate, 'dd.MM.yyyy', { locale }) : '//';

                const date = `${from} - ${to}`;

                return date;
            })
            .filter(Boolean);

        return result ?? [];
    }
}

export { SchoolEntity };
