import React from 'react';
import styled, { css } from 'styled-components';
import { CloudUpload } from '@material-ui/icons';
import { black, gray, red } from '@avangard/ui/colors';

type UploadHandlerProps = {
    title: string;
    withoutIcon?: boolean;
    onClick: () => void;
    dense?: boolean;
    className?: string;
};

const Handler = styled.div<Pick<UploadHandlerProps, 'dense'>>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 32px;
    padding: 4px 16px;
    color: ${black[60]};
    border: 1px dashed ${gray[40]};
    border-radius: 4px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
        color: ${red[50]};
        border-color: ${red[50]};
    }

    ${p =>
        !!p.dense &&
        css`
            min-height: initial;
        `}
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    > span {
        display: inline-flex;
        margin-inline-end: 8px;
    }

    > p {
        line-height: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const uploadIcon = <CloudUpload style={{ fontSize: 20 }} />;

const UploadHandler = (props: UploadHandlerProps): React.ReactElement => {
    const { title, withoutIcon, ...otherProps } = props;

    return (
        <Handler {...otherProps}>
            <Content>
                {!withoutIcon ? <span>{uploadIcon}</span> : null}
                <p>{title}</p>
            </Content>
        </Handler>
    );
};

export { UploadHandler };
