import _intersection from 'lodash/intersection';

import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';
import { i18n } from '@lib/i18n';
import { StudentEntity } from '@modules/students/entities';
import { SchoolEntity } from '@modules/schools/entities';
import { roleTranslates } from '@modules/user/constants';
import { UserRole } from '@modules/types/graphql';

import type { StudentEntityType } from '@modules/students/entities';
import type { SchoolEntityType } from '@modules/schools/entities';
import type { User } from '@modules/types/graphql';

export type Entity =
    | (Partial<Omit<User, 'student' | 'school'>> & {
          student?: StudentEntityType | null;
          school?: SchoolEntityType | null;
      })
    | null;

@DecribeEntity('UserEntity')
class UserEntity extends BaseEntity {
    id: string;
    username: string;
    email: string;
    fullName: string;
    roles: (UserRole | null)[];

    @Relation(() => StudentEntity)
    student: StudentEntity;

    @Relation(() => SchoolEntity)
    school: SchoolEntity;

    constructor(user: Entity) {
        super(user);

        this.id = user?.id ?? '';
        this.username = user?.username ?? '';
        this.email = user?.email ?? '';
        this.fullName = user?.fullName ?? '';
        this.roles = user?.roles ?? [];
    }

    getFullNameByRole() {
        if (this.isStudent()) {
            return this.student.fullName;
        }

        if (this.isSchool()) {
            return this.school.shortName;
        }

        return this.fullName;
    }

    getFullNameWithRole() {
        const role = this.getRole().toLowerCase();
        const fullName = this.getFullNameByRole();

        return `${role} ${fullName}`;
    }

    getRole() {
        if (this.roles.length === 0) {
            return '-';
        }

        if (this.roles && this.roles[0]) {
            const role = this.roles[0];

            return i18n.t(roleTranslates[role]);
        }

        return this.roles.toString();
    }

    getCurrentRole() {
        const role = this.roles?.[0];

        return role;
    }

    authenticated() {
        const isAuth = this.id !== '';

        return isAuth;
    }

    isAdmin() {
        const isAdmin = this.roles.includes(UserRole.admin);

        return isAdmin;
    }

    isSchool() {
        const isSchool = this.roles.includes(UserRole.school);

        return isSchool;
    }

    isInstructor() {
        const isInstructor = this.roles.includes(UserRole.instructor);

        return isInstructor;
    }

    isLeader() {
        const isLeader = this.roles.includes(UserRole.leader);

        return isLeader;
    }

    isMentor() {
        const isMentor = this.roles.includes(UserRole.mentor);

        return isMentor;
    }

    isMethodist() {
        const isMethodist = this.roles.includes(UserRole.methodist);

        return isMethodist;
    }

    isRegistrar() {
        const isRegistrar = this.roles.includes(UserRole.registrar);

        return isRegistrar;
    }

    isStudent() {
        const isStudent = this.roles.includes(UserRole.student);

        return isStudent;
    }

    isOrganizingTeacher() {
        const result = this.roles.includes(UserRole.organizingTeacher);

        return result;
    }

    isEmployee(withAdmin?: boolean) {
        const roles = [
            UserRole.registrar,
            UserRole.instructor,
            UserRole.methodist,
            UserRole.leader,
            UserRole.seniorInstructor,
            UserRole.mentor,
            UserRole.juniorLeader,
            UserRole.psychologist,
            UserRole.organizingTeacher,
        ];

        if (withAdmin) {
            roles.push(UserRole.admin);
        }

        return this.hasAccess(roles);
    }

    hasAccess(roles?: UserRole[]) {
        if (!roles) {
            return true;
        }

        const hasAccess = _intersection(this.roles, roles).length !== 0;

        return hasAccess;
    }

    hasAccessLesson(instructorId: string) {
        const hasAccessLesson = this.id === instructorId;

        return hasAccessLesson;
    }
}

export { UserEntity };
