import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Location } from '@modules/types/graphql';

export type Entity = Location | null;

@DecribeEntity('LocationEntity')
class LocationEntity extends BaseEntity {
    id: string;
    name: string;
    regionId: string;

    constructor(location: Entity) {
        super(location);

        this.id = location?.id ?? '';
        this.name = location?.name ?? '-';
        this.regionId = location?.regionId ?? '';
    }
}

export { LocationEntity };
