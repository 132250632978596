import { routes } from '@config/routes';
import { Redirect } from '@lib/routing';
import { UserEntity } from '@modules/user/entities';
import { UserRole } from '@modules/types/graphql';

const hasAccessToDashboard = [UserRole.superAdmin];

const redirectByRoles = (currentUser: UserEntity) => {
    if (currentUser.hasAccess(hasAccessToDashboard)) {
        return <Redirect noThrow to={routes.dashboard.path} />;
    }

    return <Redirect noThrow to={routes.error.path} />;
};

export { redirectByRoles };
