import loadable from '@loadable/component';
import { Dashboard, LocationOn } from '@material-ui/icons';

import { BaseLayout, EmptyLayout, ErrorLayout } from '@modules/layout/templates';
import { UserRole } from '@modules/types/graphql';

import type { RedirectProps, RouteComponentProps } from '@reach/router';
import type { LoadableComponent } from '@loadable/component';

export enum RouteMenuLocationEnum {
    sidebar = 'sidebar',
}

export type OriginRoute = {
    name: string;
    title: string;
    path: string;
    private: boolean;
    LayoutComponent: React.FunctionComponent<any>;
    componentPath?: string;
    renderForPath?: Partial<Record<UserRole, string>>;
    redirectTo?: string;
    withBackIcon?: boolean;
    default?: boolean;
    roles?: UserRole[];
    titleFor?: Partial<Record<UserRole, string>>;
    menuTitle?: string;
    menuTitleFor?: Partial<Record<UserRole, string>>;
    menuLocations?: RouteMenuLocationEnum[];
    menuIcon?: React.FunctionComponent<any>;
    children?: React.ReactNode;
};

export type Route = Omit<OriginRoute, 'renderForPath' | 'componentPath'> & {
    renderFor?: Partial<Record<UserRole, LoadableComponent<any>>>;
    Component?: LoadableComponent<any>;
};

export type OriginRoutes = Record<string, OriginRoute>;
export type ConfigRoutes = Record<string, Route>;

export type RedirectRoute = RouteComponentProps<RedirectProps<{}>> & {
    name: string;
};

const originRoutes: OriginRoutes = {
    index: {
        name: 'index',
        title: 'index',
        path: '/',
        private: true,
        componentPath: '@pages',
        LayoutComponent: EmptyLayout,
    },
    dashboard: {
        name: 'dashboard.index',
        title: 'common:menu.dashboard',
        path: '/dashboard',
        private: true,
        roles: [UserRole.superAdmin],
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuIcon: Dashboard,
        componentPath: '@pages/dashboard',
        LayoutComponent: BaseLayout,
    },

    regionalCenters: {
        name: 'regional.index',
        title: 'common:menu.regional_centers',
        path: '/regional-centers',
        private: true,
        roles: [UserRole.superAdmin],
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuIcon: LocationOn,
        componentPath: '@pages/regional-center',
        LayoutComponent: BaseLayout,
    },
    regionalCenterEditPage: {
        name: 'regional.edit',
        title: 'common:actions.edit',
        path: '/regional-centers/:id/edit',
        private: true,
        withBackIcon: true,
        roles: [UserRole.superAdmin],
        componentPath: '@pages/regional-center/edit',
        LayoutComponent: BaseLayout,
    },

    profile: {
        name: 'profile.index',
        title: 'common:menu.profile',
        path: '/profile',
        private: true,
        componentPath: '@pages/profile',
        LayoutComponent: BaseLayout,
    },

    error: {
        name: 'error',
        title: 'Error Page',
        path: '/error',
        private: false,
        componentPath: '@pages/error', // TODO: Create Error page
        LayoutComponent: ErrorLayout,
    },
};

const routes = Object.entries(originRoutes).reduce((carry, [key, route]) => {
    let asyncComponent: Route['Component'] = undefined;
    let renderFor: Route['renderFor'] = undefined;

    if (route.componentPath) {
        const path = route.componentPath.replace('@', '');

        asyncComponent = loadable(() => import(/* webpackPrefetch: true */ `../${path}`));
    }

    if (route.renderForPath) {
        renderFor = Object.entries(route.renderForPath).reduce((carry, [role, componentPath]) => {
            if (componentPath) {
                const path = componentPath.replace('@', '');
                const asyncComponent = loadable(
                    () => import(/* webpackPrefetch: true */ `../${path}`),
                );

                return { ...carry, [role]: asyncComponent };
            }

            return carry;
        }, {});
    }

    return {
        ...carry,
        [key]: { ...route, renderFor, Component: asyncComponent },
    };
}, {} as ConfigRoutes);

const redirects: RedirectRoute[] = [
    {
        name: 'default',
        from: '/:lang',
        to: routes.index.path,
        default: true,
    },
];

export { routes, redirects };
