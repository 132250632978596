export {
    performanceManagmentTranslates,
    performanceManagementOptions,
} from './performance-management-translates';
export { creditOfPointTranslates, creditOfPointOptions } from './credit-of-point-translates';
export { HasCertificate, hasCertificateTranslates } from './has-certificate-translates';
export { Interest, interestTranslates, interestOptions } from './interest-translates';
export {
    SportCategory,
    sportCategoryTranslates,
    sportCategoryOptions,
} from './sport-category-translates';
