import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import format from 'date-fns/format';

import { StringHelper } from '@helpers';
import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';
import { i18n, getLocaleForDateFns } from '@lib/i18n';
import { CourseEntity } from '@modules/courses/entities';
import { programTypeTranslates } from '@modules/lap/constants';
import { Platform } from '@modules/types/graphql';

import type { Lap, LapProgramType } from '@modules/types/graphql';

export type Entity = Partial<Lap> | null;

@DecribeEntity('LapEntity')
class LapEntity extends BaseEntity {
    id: string;
    name: string;
    from: string;
    to: string;
    platform: Platform | null;
    isCurrent: boolean;
    programType: LapProgramType | null;
    companyCount: number;
    platoonCount: number;

    @Relation(() => CourseEntity)
    course: CourseEntity;

    constructor(lap: Entity) {
        super(lap);

        this.id = lap?.id ?? '';
        this.name = lap?.name ?? '';
        this.from = lap?.from ?? '';
        this.to = lap?.to ?? '';
        this.platform = lap?.platform ?? null;
        this.isCurrent = lap?.isCurrent ?? false;
        this.programType = lap?.programType ?? null;
        this.companyCount = lap?.companyCount ?? 0;
        this.platoonCount = lap?.platoonCount ?? 0;
    }

    get platoonXCount() {
        if (!this.companyCount || !this.platoonCount) {
            return 0;
        }

        return this.companyCount * this.platoonCount;
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }

    getProgramType() {
        if (!this.programType) {
            return null;
        }

        const programType = programTypeTranslates[this.programType];

        return i18n.t(programType);
    }

    getPlatformTitle() {
        if (!this.platform) {
            return null;
        }

        const title = StringHelper.capitalizeFirstLetter(this.platform);

        return title;
    }

    getFullDate() {
        const fromDate = this.from ? new Date(this.from) : null;
        const toDate = this.to ? new Date(this.to) : null;

        const locale = getLocaleForDateFns();

        const from = fromDate ? format(fromDate, 'dd.MM.yyyy', { locale }) : '//';
        const to = toDate ? format(toDate, 'dd.MM.yyyy', { locale }) : '//';

        const date = `${from} - ${to}`;

        return date;
    }

    getDate(dateString: string): Date | null {
        if (!dateString) {
            return null;
        }

        const date = new Date(dateString);

        return date;
    }

    hasCurrentDateInLap() {
        if (!this.from || !this.to) {
            return false;
        }

        const currentDate = new Date().getTime();
        const fromDate = new Date(this.from).getTime();
        const toDate = new Date(this.to).getTime();

        const isIncluded = currentDate >= fromDate && currentDate <= toDate;
        const isAfter = currentDate > toDate;

        return isIncluded || isAfter;
    }

    getDaysList() {
        const start = this.from ? new Date(this.from) : null;
        const end = this.to ? new Date(this.to) : null;

        if (!start || !end) {
            return [];
        }

        const interval = eachDayOfInterval({ start, end });

        const list = interval.map(date => ({
            date: format(date, 'yyyy-MM-dd'),
            formattedDate: format(date, 'EEEEEE, dd.MM.yyyy', { locale: getLocaleForDateFns() }),
        }));

        return list;
    }

    isActiveLap(currentLap: LapEntity) {
        if (!currentLap.exists() || !this.id) {
            return false;
        }

        const isActiveLap = currentLap.id === this.id;

        return isActiveLap;
    }

    isCenterLap() {
        const isCenterLap = this.platform === Platform.center;

        return isCenterLap;
    }

    isOnlineLap() {
        const isOnlineLap = this.platform === Platform.online;

        return isOnlineLap;
    }

    getFormattedName(withPlatform: boolean = true) {
        let name = this.name;
        const platform = this.getPlatformTitle();
        const courseName = this.course.name;
        const platformTitle = this.getProgramType();

        if (platform && withPlatform) {
            name += ` • ${platform}`;
        }

        if (courseName || platformTitle) {
            name += ` • ${courseName || platformTitle}`;
        }

        return name;
    }
}

export { LapEntity };
