import { relationsPropertiesSymbol, relationsListPropertiesSymbol } from '@lib/entity/decorators';

type BaseEntityConstructorOptions = {
    __from?: string;
} & Record<string, any>;

abstract class BaseEntity {
    constructor(options: BaseEntityConstructorOptions | null) {
        const relationsProperties: string[] =
            Reflect.getMetadata(relationsPropertiesSymbol, this) || [];

        const relationsListProperties: string[] =
            Reflect.getMetadata(relationsListPropertiesSymbol, this) || [];

        relationsProperties.forEach(key => this.setRelationProperty(options, key));
        relationsListProperties.forEach(key => this.setRelationListProperty(options, key));
    }

    getFieldOrDefaultValue(field: string, defaultValue: any = '-') {
        if (!this[field]) {
            return defaultValue;
        }

        return this[field];
    }

    //
    private setRelationProperty(
        options: BaseEntityConstructorOptions | null,
        propertyKey: string,
    ): void {
        this[propertyKey] = {
            __from: options?.__from,
            args: options?.[propertyKey] ?? null,
        };
    }

    //
    private setRelationListProperty(
        options: BaseEntityConstructorOptions | null,
        propertyKey: string,
    ): void {
        this[propertyKey] = options?.[propertyKey] ?? [];
    }

    //
    private relationKeyToSnakeCase(propertyKey: string): string {
        return propertyKey.replace(/[A-Z]/g, search => {
            const letter = search.toLowerCase();

            return '_' + letter;
        });
    }

    *[Symbol.iterator](): Generator {
        for (const key in this) {
            yield this[key];
        }
    }
}

export { BaseEntity };
