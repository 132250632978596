export { FileDocFragment, FileInfoFragment } from './fragments';
export {
    GetRegionsQuery,
    GetLocationsQuery,
    GetSchoolTypesQuery,
    GetSchoolNumbersQuery,
} from './queries';
export { UploadFileRestMutation, UpdateFileCropMutation } from './mutations';

export type { UploadFileRestMutationType } from './mutations';
