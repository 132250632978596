import React from 'react';

import { TokenSession } from '@lib/token';
import { useNavigate, changePlatform, Redirect } from '@lib/routing';
import { routes } from '@config/routes';
import { useCurrentUser } from '@modules/user/hooks';
import { useHasAccessForPlatform } from '@modules/auth/hooks';

import type { Route } from '@config/routes';

const PrivateRoute = (props: Route): React.ReactElement | null => {
    const { redirectTo, roles, renderFor, titleFor, Component, LayoutComponent, ...otherProps } =
        props;

    const navigate = useNavigate();

    const hasTokens = TokenSession.getCurrentSession().hasTokens();

    const {
        currentUser,
        loading: userLoading,
        error: userError,
    } = useCurrentUser({ skip: !hasTokens });

    const role = currentUser.getCurrentRole();

    const { hasAccessForPlatform, loading: hasAccessForPlatformLoading } =
        useHasAccessForPlatform();

    React.useEffect(() => {
        if (redirectTo) {
            navigate(redirectTo);
        }
    }, []);

    if (userLoading || hasAccessForPlatformLoading) {
        return null;
    }

    if (userError && !currentUser.authenticated()) {
        TokenSession.destroyCurrentSession();
        changePlatform();
    }

    if (!hasAccessForPlatform && currentUser.authenticated()) {
        return <Redirect noThrow to={routes.error.path} />;
    }

    if (!currentUser.hasAccess(roles)) {
        return <Redirect noThrow to={routes.index.path} />;
    }

    let pageTitle = otherProps.title;

    if (role && titleFor?.[role]) {
        pageTitle = titleFor[role] ?? otherProps.title;
    }

    if (role && renderFor?.hasOwnProperty(role)) {
        const RoleComponent = renderFor[role];

        return (
            <LayoutComponent pageTitle={pageTitle} withBackIcon={otherProps.withBackIcon}>
                {RoleComponent ? <RoleComponent {...otherProps} /> : null}
            </LayoutComponent>
        );
    }

    return (
        <LayoutComponent pageTitle={pageTitle} withBackIcon={otherProps.withBackIcon}>
            {Component && !redirectTo ? <Component {...otherProps} /> : null}
        </LayoutComponent>
    );
};

export { PrivateRoute };
