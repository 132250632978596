import { Platform } from '@modules/types/graphql';

const platformTranslates = {
    [Platform.center]: 'Center',
    [Platform.online]: 'Online',
};

const platforms = Object.entries(Platform)
    .map(([key, value]) => {
        const translation = platformTranslates[value];

        if (translation) {
            return {
                key,
                value: platformTranslates[value],
            };
        }

        return null;
    })
    .filter(Boolean) as AutocompleteOption[];

export { platformTranslates, platforms };
