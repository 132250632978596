export enum HasCertificate {
    empty = '',
    true = 'true',
    false = 'false',
}

export const hasCertificateTranslates = {
    [HasCertificate.empty]: 'students:blocks.has_certificate.empty',
    [HasCertificate.true]: 'students:blocks.has_certificate.true',
    [HasCertificate.false]: 'students:blocks.has_certificate.false',
};
