import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Region } from '@modules/types/graphql';

export type Entity = Region | null;

@DecribeEntity('RegionEntity')
class RegionEntity extends BaseEntity {
    id: string;
    name: string;
    order: number;

    constructor(entity: Entity) {
        super(entity);

        this.id = entity?.id ?? '';
        this.name = entity?.name ?? '-';

        this.order = entity?.order ?? 0;
    }
}

export { RegionEntity };
