import _intersection from 'lodash/intersection';

import { routes, RouteMenuLocationEnum } from '@config/routes';

import type { Route } from '@config/routes';
import type { UserRole } from '@modules/types/graphql';

type GetMenuRoutesOptions = {
    forRoles?: (UserRole | null)[];
};

export const getMenuRoutes = (
    location: RouteMenuLocationEnum,
    options?: GetMenuRoutesOptions,
): Route[] => {
    let menuRoutes = Object.values(routes).filter(
        route =>
            typeof route.menuLocations !== 'undefined' && route.menuLocations.includes(location),
    );

    if (options?.forRoles) {
        menuRoutes = menuRoutes.filter(
            route => _intersection(route.roles, options.forRoles).length !== 0,
        );
    }

    return menuRoutes;
};
