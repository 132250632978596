enum Integration {
    eljur = 'eljur',
}

const integrationTranslates = {
    [Integration.eljur]: 'Eljur',
};

const integrations = Object.entries(Integration)
    .map(([key, value]) => {
        const translation = integrationTranslates[value];

        if (translation) {
            return {
                key,
                value: integrationTranslates[value],
            };
        }

        return null;
    })
    .filter(Boolean) as AutocompleteOption[];

export { Integration, integrationTranslates, integrations };
