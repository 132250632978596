import { EntityList } from '@lib/entity';
import { CourseEntity } from './course.entity';

import type { Entity } from './course.entity';

class CourseEntityList extends EntityList<CourseEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(CourseEntity, items, count);
    }
}

export { CourseEntityList };
